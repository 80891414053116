<template>
  <div>

  </div>
</template>
<script>
  export default {
    mounted() {
      let device = this.$device
      let os = ''
      if (device.ios) {
        os = "ios"
        window.location = 'https://apps.apple.com/us/app/shayen/id1631142462'
      } else if (device.android) {
          window.location = 'https://play.google.com/store/apps/details?id=com.shayen.app'
        os = "android"
      } else if (device.windows) {
          window.location = 'https://play.google.com/store/apps/details?id=com.shayen.app'
        os = "windows"
      }
    },
  }
</script>
